import '/assets/styles/partials/productbox/types/regular.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';

// Context
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Label from '/views/partials/label';
import Placeholder from '/views/partials/placeholder';
import Btn from '/views/partials/btn';
import Pricebox from '/views/partials/pricebox';

const RegularProductbox = ({ product, className, basketProductControls, linkParams }) => {
	const { mobile } = useBreakpoints();
	const containerClass = 'productbox type-regular ' + className ?? '';

	const { busy, increaseQuantity } = basketProductControls;
	
	const productImage = useMemo(() => (product?.images?.[0] ? product.image_types.mini + product.images[0].url : false), [product])

	const hasLabels = useMemo(() => {
		return product && product?.quantity !== 0 && (product.is_internet_exclusive || product.is_outlet || product.new_product === true || product.bestseller);
	}, [product])

	const hasSlogan = useMemo(() => product?.slogan && product.slogan.length > 0, [product]);
	const hasCargoLabel = useMemo(() => product?.cargo_day === 0, [product]);

	if (product && linkParams.props.href) {
		return (
			<div className={containerClass}>
				<Link
					data-gtm-id="productLink"
					className="productbox-wrap"
					{...linkParams.props}>
					{hasLabels &&
						<div className="productbox-labels top">
							{product.is_internet_exclusive ?
								<Label className="small uppercase blue-bg">İnternete Özel</Label>
								:
								<>
									{product.is_outlet ?
										<Label className="small uppercase pink-bg">Outlet</Label>
										:
										<>
											{product.bestseller === true ?
												<Label className="small uppercase orange-bg">En Çok Satan</Label>
												:
												<>
													{product.new_product === true &&
														<Label className="small uppercase orange-light-bg">Yeni</Label>
													}
												</>
											}
										</>
									}
								</>
							}
						</div>
					}
					<div className="productbox-imagewrap">
						<Img lazy className="imagewrap-image" src={productImage} />
					</div>
					<div className="productbox-textarea">
						<strong className="productbox-title">{product.product_name}{/*linkParams.type === 'with-serial' ? ` - ${product.serial_title}`: ''*/}</strong>
						<Pricebox className="productbox-price" product={product} />
						{(hasSlogan || hasCargoLabel) &&
							<div className="productbox-labels bottom">
								{hasSlogan &&
									<span className={`label pink-bg${mobile ? ' small' : ''}`}>{product.slogan}</span>
								}
								{hasCargoLabel &&
									<span className={`label pink-bg${mobile ? ' small' : ''}`}>Aynı Gün Kargo</span>
								}
							</div>
						}
					</div>
				</Link>
				{!mobile &&
					<div className="productbox-controls">
						{product.direct_basket ?
							<>
								{product.quantity === 0 ?
									<>
										<Btn
											disabled
											className="small primary block">
											Stokta Yok
										</Btn>
									</>
									:
									<Btn
										data-gtm-id="addToBasket"
										disabled={busy}
										loading={busy}
										onClick={increaseQuantity}
										className="small primary block">
										Sepete Ekle
									</Btn>
								}
							</>
						:
							<>
								<p className="controls-message">Farklı seçenekleri var.</p>
								<Btn
									tag="link"
									{...linkParams.props}
									className="small primary block">
									Ürüne Git
								</Btn>
							</>
						}
					</div>
				}
			</div>
		)
	}
	else {
		return (
			<div className={containerClass}>
				<div className="productbox-wrap">
					<Placeholder className="productbox-imagewrap" />
					<Placeholder className="productbox-title" />
					<Placeholder className="productbox-price" />
				</div>
			</div>
		)
	}
}

RegularProductbox.propTypes = {
	product: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([false])]),
	className: PropTypes.string,
	onUpdateQuantity: PropTypes.func,
	basketProductControls: PropTypes.object,
	// basketProduct: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([false])]),
	linkParams: PropTypes.object,
}

export default RegularProductbox