import '/assets/styles/partials/label.scss';
import PropTypes from 'prop-types';

export const Label = ({ className, children, ...props }) => {
	return (
		<span className={`label ${className ?? ''}`} {...props}>
			{children}
		</span>
	)
}

Label.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
}

export default Label;